import React from "react";
const QuizLungPage = () => {
    return (
        <>
            <iframe className="w-[100%] h-screen"
                id="JotFormIFrame-231138033663450"
                title="Indo Default Mirxes Lung Cancer Survey"
                // onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                allowfullscreen="true"
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/231138033663450"
                // frameborder="0"
            >
            </iframe>
        </>
    );
}
export default QuizLungPage;