export function ExternalAverage() {
    window.location.href = 'https://bumame.com/quiz/averagerisk-lungclear/';
    return null;
  }
  export function ExternalLow() {
    window.location.href = 'https://bumame.com/quiz/lowrisk-lungclear/';
    return null;
  }
  export function ExternalHigh() {
    window.location.href = 'https://bumame.com/quiz/highrisk-lungclear/';
    return null;
  }
  export function ExternalLandingParuPage() {
    window.location.href = 'https://bumame.com/quiz/lungclear/ ';
    return null;
  }