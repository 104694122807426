
import './App.css';
import {Route, Routes } from "react-router";
import {Navigate} from 'react-router-dom';
// import LandingPageLungs from './pages/HomePage/HomePageLungs';
import { BrowserRouter } from 'react-router-dom';
// import AverageRiskLungClear from './pages/ResultPageLungClear/AverageRisk';
// import HighRiskLungClear from './pages/ResultPageLungClear/HighRisk';
// import LowRiskLungClear from './pages/ResultPageLungClear/LowRisk';
import QuisionerPage from './pages/QuisionerPage/index';
import "@fontsource/metropolis";
import NotFoundPage from './pages/404/index';
import { ExternalAverage, ExternalLow, ExternalHigh, ExternalLandingParuPage } from './pages/ResultPageLungClear/ExtenalLink';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ExternalLandingParuPage />} />
          <Route path="/averagerisk-lungclear" element={<ExternalAverage />} />
          <Route path="/highrisk-lungclear" element={<ExternalHigh />} />
          <Route path="/lowrisk-lungclear" element={<ExternalLow />} />
          <Route path='/quisioner-lungclear' element={<QuisionerPage/>}/>
          <Route path='/404' element={<NotFoundPage/>}/>
          <Route path='*' element={<Navigate to="/404" />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
